import * as React from 'react';
import IconLoaders from "../icons/IconLoaders";

const getGatsbyImage = (url, sizes, src_sizes, width, height) => {
    let srcsetWebp = '';
    let srcsetAvif = '';
    let srcset = '';
    // get the extension of the image url, ensure not to include the query string
    const ext = url.split('?')[0].split('.').pop();
    const extRegExp  = new RegExp(`[.]${ext}`)

    for(const size of src_sizes) {
        if(url.match(/\/o\//)) {
            srcset += url.replace(/\/o\//, '/s/' ).replace(extRegExp, `-w${size}.${ext}`) + ` ${size}w,\n`;
            srcsetWebp += url.replace(/\/o\//, '/s/').replace(extRegExp, `-w${size}.${ext}.webp`) + ` ${size}w,\n`;
            srcsetAvif += url.replace(/\/o\//, '/s/').replace(extRegExp, `-w${size}.${ext}.avif`) + ` ${size}w,\n`;
        } else if(url.match(/\/w\//)) {
            srcset += url.replace(extRegExp, `-${size}x.${ext}.auto`)  + ` ${size}w,\n`;
            srcsetWebp += url.replace(extRegExp, `-${size}x.${ext}.webp`)  + ` ${size}w,\n`;
            srcsetAvif += url.replace(extRegExp, `-${size}x.${ext}.avif`)  + ` ${size}w,\n`;
        }
    }
    return {
        "gatsbyImageData": {
            "layout": "constrained",
            "backgroundColor": "#FFFFFF",
            "placeholder": {
                children: <IconLoaders />,
                className: "m-1"
            },
            "images": {
                "fallback": {
                    "src": url,
                    "srcSet": srcset,
                    "sizes": sizes
                },
                "sources": [
                    {
                        "srcSet": srcsetAvif,
                        "type": "image/avif",
                        "sizes": sizes
                    },
                    {
                        "srcSet": srcsetWebp,
                        "type": "image/webp",
                        "sizes": sizes
                    }
                ]
            },
            "width": width,
            "height": height
        }
    };
}

const getGatsbyImage80 = (url) => {
    const sizes = "(min-width: 768px) 25vw, (min-width: 1024px) 33.3333333vw, 100vw";
    const src_sizes = [360, 375, 414, 640, 720, 750, 768];
    return getGatsbyImage(url, sizes, src_sizes, 320, 320);
}

const getGatsbyImageHero = (url) => {
    const sizes = "100vw";
    let src_sizes = [360, 375, 414, 640, 720, 750, 768, 828, 1024, 1080, 1125, 1242, 1280, 1536, 1920, 2000];
    return getGatsbyImage(url, sizes, src_sizes, 1024, 768);
}

export { getGatsbyImage80, getGatsbyImageHero };