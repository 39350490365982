import * as React from 'react';
import { memo } from 'react';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {getGatsbyImage80} from "../helpers/GatsbyImageHelper";
import PropTypes from 'prop-types';

const HeroImage = memo(({ url, alt, className }) => {
    let image = getGatsbyImage80(url);
    return (image ?
        <GatsbyImage
            className={className}
            alt={alt}
            image={getImage(image)}/>
        :
        <div className={className}/>);
});

HeroImage.propTypes = {
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string
}


export default HeroImage;